<template>
      <step :step="stepPage" @nextPage="nextStep" @backPage="backStep" :question="question" :responses="responses" @finish="finishProcess"  @result="getResult" @getQuestion="getQuestion"></step>
</template>

<script>
import step from "@/components/step"

export default {
  name: "Home",
  data() {
    return {
      stepPage: 0,
      responses: [],
      question: ""
    }
  },
  components: {
    step
  },
  methods: {
    getQuestion() {
      fetch('https://tulegadoderiqueza.com/admin_diagnostico/?pregunta=' + this.stepPage)
          .then(response => response.json())
          .then(data => {
            this.question = data.pregunta
            this.responses = data.respuestas
          })
    },
    nextStep() {
      if(this.stepPage === 0){
        localStorage.removeItem('sended')
        localStorage.removeItem('stepPage')
        localStorage.removeItem('responses')
        localStorage.removeItem('urlPdf')
      }
      this.stepPage++
      //save in steppage in localstorage
      localStorage.setItem("stepPage", this.stepPage)
      this.getQuestion()
      this.$router.replace('/step/'+this.stepPage+'/')
    },
    backStep() {
      this.stepPage--
      localStorage.setItem("stepPage", this.stepPage)
      this.getQuestion()
      this.$router.replace('/step/'+this.stepPage+'/')
    },
    parametros(){
      let params = this.$route.params.step
      if(params!=='' && params!==undefined){
        if(!isNaN(params)){
          let step = parseInt(this.$route.params.step)
          if(this.stepPage===step){

          }else{
            this.$router.replace('/step/'+this.stepPage+'/')
          }
        }else{
          this.$router.replace('/')
        }
      }
    },
    finishProcess(){
      this.stepPage++
      localStorage.setItem("stepPage", this.stepPage)
      this.$router.replace('/finish')
    },
    getResult(){
      this.$router.replace('/result')
    }
  },
  created() {
    if (localStorage.getItem('stepPage')) {
      this.stepPage = JSON.parse(localStorage.getItem('stepPage'))
    }
    this.parametros()
    //fetch question with fetch
    if(this.stepPage>0){
      this.getQuestion()
    }
  }
}
</script>

<style scoped>

</style>