import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Result from "../views/Result";
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
    //Create step with variable number of parameters
    {
        path: '/step/:step',
        name: 'step',
        component: Home
    },
    {
        path: '/result',
        name: 'result',
        component: Result
    }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
