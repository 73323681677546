<template>
  <div class="container-fluid justify-content-center vh-100 body">
    <div class="row justify-content-center ">
      <div class="col-12 col-lg-6 d-flex  justify-content-center align-items-center px-0">
        <div class="row justify-content-center titulo row__width" >
          <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in">
            <div class="col-11 col-lg-7  d-flex flex-column justify-content-center align-items-start justify-content-lg-start" :key="step">
              <h2 v-if="step>0 && step<limitPage"><div v-if="step<10">0</div>{{step}} <span>/ 12</span></h2>
              <h2 v-else-if="step===limitPage">¡Hemos finalizado!</h2>
              <h2 v-else>Comencemos...</h2>
              <h3 v-if="step===0">Para empezar, nos gustaría conocer tu nombre</h3>
              <h3 v-else-if="step===limitPage">Por favor ingresa tu dirección de email para conocer tus resultados</h3>
              <h3 class="d-flex" v-else>{{ question }}</h3>
              <h6 v-if="step===3">En relación a la cantidad de fuentes de ingresos</h6>
              <h6 v-if="step===6">En relación con tus gastos</h6>
              <h6 v-if="step===7">¿llevas un control de gastos mensuales, usas una aplicación o lo haces manualmente en un excel?</h6>
              <h6 v-if="step===11">¿Tienes inversiones o negocios que te produzcan dinero?</h6>
              <p>DIAGNÓSTICO FINANCIERO LEGADO DE RIQUEZA</p>
            </div>
          </transition>
        </div>
      </div>
      <div class="col-12 col-lg-6 d-flex  justify-content-center align-items-center px-0">
        <div class="row justify-content-center justify-content-lg-start contenido row__width px-lg-5">
          <div class="col-11 col-lg-10 max-width-desk d-flex flex-column justify-content-center align-items-center position-relative">
            <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in">
            <div class="row justify-content-center row__width position-relative" :key="step">
                <div class="col-12 d-flex justify-content-center align-items-center preguntas" v-if="step>0 && step<limitPage" v-for="(res,index) in responses" :index="index">
                  <input type="radio" value="{{ res.id_respuesta }}" name="respuestas" :id="'num'+index" :checked="index === activeResponse">
                  <label :for="'num'+index" @click="nextStep(index,res)">
                    <div class="d-flex justify-content-center align-items-start">
                      <span class="text-uppercase">{{ getLetter(index) }}</span>
                      <h4 class="d-flex">{{ res.respuesta }}</h4>
                    </div>
                  </label>
                </div>
              <span v-if="errorStep" class="text-danger position-absolute" style="bottom: -10px;left: 20px;">Por favor seleccione una respuesta para continuar</span>
              <div class="col-12 d-flex flex-column justify-content-center align-items-start preguntas position-relative" v-if="step===0">
                <input type="text" name="nombre" id="nombre" class="d-flex" placeholder="Ingresa tu nombre" @keyup.enter="nextStep(null,null)" v-model="info.name">
                <span v-if="errorName" class="text-danger position-absolute" style="bottom: 62px;left: 20px;">Por favor ingrese su nombre</span>
                <button class="mt-3" @click="nextStep(null,null)">Siguiente <img src="../assets/img/next.svg" alt="" class="ms-2"></button>
              </div>
              <div class="col-12 d-flex flex-column justify-content-center align-items-start preguntas position-relative" v-if="step===limitPage">
                <input type="text" name="email" id="email" class="d-flex" placeholder="Ingresa tu email" @keyup.enter="nextStep(null,null)" v-model="info.email">
                <span v-if="errorEmail" class="text-danger position-absolute" style="bottom: 62px;left: 20px;">Por favor ingrese su email</span>
                <button class="mt-3" @click="nextStep(null,null)">Siguiente <img src="../assets/img/next.svg" alt="" class="ms-2"></button>
              </div>
            </div>
            </transition>
            <div class="row row__width footer justify-content-center align-items-center">
              <div class="col d-flex flex-column justify-content-center align-items-center">
                <div class="before">
                  <span :style="percent"></span>
                </div>
              </div>
              <div class="col d-flex justify-content-center align-items-center botones">
                <button  @click="backStep()" type="button" :disabled="calcBackButton" :class="calcBackButton ? 'disabled' : ''"><img src="../assets/img/arrow_arriba.svg" alt=""></button>
                <button @click="nextStep(null,null)" type="button" :disabled="calcNextButton" :class="calcNextButton ? 'disabled' : ''"><img src="../assets/img/arrow_abajo.svg" alt=""></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row__width footer justify-content-center align-items-center d-lg-none">
      <div class="col d-flex flex-column justify-content-center align-items-center">
        <div class="before">
          <span :style="percent"></span>
        </div>
        <p>DIAGNÓSTICO FINANCIERO LEGADO DE RIQUEZA</p>
      </div>
      <div class="col d-flex justify-content-center align-items-center botones">
        <button  @click="backStep()" :disabled="calcBackButton" :class="calcBackButton ? 'disabled' : ''" type="button"><img src="../assets/img/arrow_arriba.svg" alt=""></button>
        <button @click="nextStep(null,null)" type="button" :disabled="calcNextButton" :class="calcNextButton ? 'disabled' : ''"><img src="../assets/img/arrow_abajo.svg" alt=""></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "step",
  data() {
    return {
      limitPage:13,
      info: {
        name: "",
        email: "",
        responses: [],
      },
      errorName:false,
      errorEmail:false,
      errorStep:false,
      activeResponse:null,
      respuestaActiva: []
    }
  },
  props: {
    step: {
      type: Number,
      required: true
    },
    question: {
      type: String,
      required: true
    },
    responses: {
      type: Array,
      required: true
    }
  },
  computed: {
    percent() {
      return 'width: '+Math.round(this.step * (100/this.limitPage))+"%;"
    },
    calcBackButton() {
      return this.step===0
    },
    calcNextButton() {
      return this.step>=this.limitPage
    },
    responseActive() {
      return this.activeResponse
    }
  },
methods: {
    nextStep(index,res) {
      this.activeResponse = this.activeResponse != null  ? this.activeResponse : index
      if(this.step===0) {
        if(this.info.name === ''){
          this.errorName=true;
          return false
        }else{
          this.errorName=false;
          localStorage.setItem("name", this.info.name);
          setTimeout(() => {
            this.$emit('nextPage')
          }, 300)
        }
      }
      if(this.step>0 && this.step<this.limitPage) {
        if(index===null) {
            this.errorStep=true;
            return false
        }else{
          this.errorStep = false
            this.info.responses[this.step]={
              id_pregunta: this.step,
              index_respuesta: index,
              respuesta: res
            }
            localStorage.setItem("responses", JSON.stringify(this.info.responses));

        }

        setTimeout(() => {
          this.$emit('nextPage')
        }, 300)
        if(index!=null && this.activeResponse!=null) {
          this.getActiveResponse(1)
        }

      }
      if(this.step === this.limitPage){
        if(this.info.email === ''){
          this.errorEmail=true;
          return false
        }else{
          this.errorEmail=false;
          localStorage.setItem("email", this.info.email);
          this.$emit('result')
        }
      }
    },
    backStep() {
      this.errorName=false
      this.errorEmail=false
      this.errorStep=false
      if(this.step>0) {
        setTimeout(() => {
        this.$emit('backPage')

        }, 300)

        this.getActiveResponse(-1)
      }
    },
    getLetter(index) {
      let letters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
      return letters[index]
    },
    getActiveResponse(x=0) {
      if(localStorage.getItem('responses')) {
        let responses = JSON.parse(localStorage.getItem('responses'))
        //if isset response.id == this.stepPage then set this.activeResponse to response
        //same function using forEach but with for
        for(let i=0; i<responses.length; i++) {
          if(responses[i]!==null) {
            if (responses[i].id_pregunta === (this.step + x)) {
              this.activeResponse = responses[i].index_respuesta
              // console.log(this.activeResponse)
              // console.log(this.step)
              this.respuestaActiva = responses[i].respuesta
              break
            }else{
              this.activeResponse = null
            }
          }else{
            this.activeResponse = null
          }
        }

        }else{
          this.activeResponse = null
        }

      }
  },
  mounted() {
    this.getActiveResponse()
  }
}
</script>

<style lang="scss">
.animate__animated.animate__fadeIn,  .animate__animated.animate__fadeOut{
  --animate-duration: 1s;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  position:fixed;
  top:0;
  right:0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fade-enter-active, .fade-leave-active {
  overflow: hidden;
}
.body{
  .row__width{
    width: 100%;
  }
  .titulo{
    background-color: #062628;
    min-height: 30vh;
    h2{
      font-family: 'Tiempos Fine Italic';
      font-style: italic;
      font-weight: 300;
      font-size: 28px;
      line-height: 34px;
      color: #E0FFD1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      div{
        margin-right: 3px;
      }
      span{
        color: #A0C2C3;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
    h3{
      font-family: 'Visby';
      font-weight: 600;
      font-size: 20px;
      color: #F9FAF4;
      padding: 20px 0px;
    }
    p{
      font-family: 'Visby';
      font-weight: 500;
      font-size: 9px;
      line-height: 136.4%;
      letter-spacing: 0.11em;
      color: #062628;
      display: none;
    }
    h6{
      font-family: 'Visby';
      font-weight: 600;
      font-size: 20px;
      line-height: 136.4%;
      display: flex;
      align-items: center;
      color: #E0FFD1;
      margin-bottom: 0;
      margin-top: 0px;
    }
    @media (min-width:992px) {
      position: relative;
      min-height: 100vh;
        h2{
          font-weight: 300;
          font-size: 56px;
          line-height: 67px;
          padding-top: 40%;
        }
        h3{
          font-weight: 600;
          font-size: 34px;
          padding-top: 30%;
        }
        p{
          display: block;
          font-weight: 500;
          font-size: 10px;
          line-height: 136.4%;
          letter-spacing: 0.365em;
          color: #F9FAF4;
          position: absolute;
          bottom: 40px;
        }
        h6{
          font-size: 24px;
        }
    }
  }
  .contenido{
    background-color: #F9FAF4;
    min-height: 65vh;
    input{
      display: none;
    }
    .preguntas:first-child{
      margin-top: 20px;
    }
    label, #nombre,#email{
      background: #F9FAF4;
      border: 1px solid rgba(162, 176, 166, 0.28);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      min-height: 12vh;
      padding: 10px;
      min-width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      span{
        border: 1px solid rgba(162, 176, 166, 0.94);
        border-radius: 6px;
        min-height: 25px;
        min-width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Visby';
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #82999A;
      }
      h4{
        font-family: 'Visby';
        font-weight: 600;
        font-size: 16px;
        line-height: 136.4%;
        color: #062628;
        min-width: 90%;
        padding: 0px 10px;
      }
      &:hover{
        background-color: #E0FFD1;
      }
    }
    #nombre,#email{
      padding-left:20px;
    }
    input:checked + label{
      background: #062628;
      h4{
        color: #F9FAF4;
      }
      span{
        background-color: #E0FFD1;
        color: #062628;
      }
    }
    @media (min-width:992px) {
      min-height: 100vh;
      .footer{
        position: absolute;
        background-color: transparent;
        max-width: 100%;
        bottom: 20px;
        .before{
          margin-bottom: 0;
        }
      }
      h4{
        font-family: 'Visby';
        font-weight: 600 !important;
        font-size: 18px;
        line-height: 136.4%;
        color: #39524A;
      }
      label{
        min-height: 13vh;
        background: #F9FAF4;
        border: 1px solid rgba(162, 176, 166, 0.94);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        padding: 8% 3% 8% 3%;
        span{
          font-size: 18px;
          line-height: 18px;
          color: #376E72;
        }
      }
    }
    button{
      width: 149px;
      height: 55px;
      background: #062628;
      border: 1px solid #D9D9D9;
      border-radius: 36px;
      font-family: 'Visby';
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1.2px;
      font-size: 16px;
      line-height: 136.4%;
      color: #F9FAF4;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .footer{
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.63);
    min-height: 8vh;
    button{
      background-color: #062628;
      border-radius: 5px;
      border: 0;
      outline: none;
      height:30px;
      width: 48px;
      margin: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .disabled{
      opacity: 0.5;
    }
    .botones{
      max-width: 30%;
      padding: 0;
      button{
        &:hover{
          cursor: pointer;
        }
      }
    }
    .before{
      width: 100%;
      height: 5px;
      background-color: #0626282c;
      margin-bottom: 10px;
      position: relative;
      span{
        height: 5px;
        background-color: #062628;
        position: absolute;
        left: 0;
      }
    }
    p{
      font-family: 'Visby';
      font-weight: 500;
      font-size: 9px;
      line-height: 136.4%;
      letter-spacing: 0.11em;
      color: #062628;
      display: none;
    }
    @media (min-width:992px) {
      position: relative;
      }
  }
  @media (min-width: 1500px) {
    .max-width-desk{
      max-width: 80%;
    }
  }
}
</style>