<template>
  <router-view/>
</template>
<style lang="scss">
@import'~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.cdnfonts.com/css/visby');

@font-face{
  font-family: 'Tiempos Fine Italic';
  src: url('@/assets/fonts/Tiempos-Fine-Italic.ttf');
}
* {
  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
  }
}
</style>
