<template>
  <main ref="pdf">
      <div class="container-fluid">
        <div class="row justify-content-center header_results">
          <div class="col-10 d-flex justify-content-between align-items-center px-0">
            <a href="https://tulegadoderiqueza.com/"><img src="../assets/img/result/logo.svg" alt=""></a>
            <a href="https://tulegadoderiqueza.com/">Ir a inicio</a>
          </div>
        </div>
        <div class="row justify-content-center py-5 contenido position-relative">
          <span class="barrita_blanca"></span>
          <div class="col-10 d-flex justify-content-center">
            <div class="row justify-content-start">
              <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start">
                <h1 class="">¡Felicidades has completado tu diagnóstico financiero!</h1>
                <h4>A continuación encontrarás el reporte de cada una de las áreas importantes de tu estabilidad financiera, así como soluciones y sugerencias que te ayudarán tomar acción y mejorartus finanzas.</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center py-5 section position-relative">
          <span class="barrita_verde"></span>
          <div>
          </div>
          <div class="col-11 col-lg-10 d-flex flex-column justify-content-center align-items-center">
            <!---->
            <article class="row row__width" v-if="ingresos.length>0">
              <div class="col-12 col-lg-6 me-lg-4 d-flex flex-column justify-content-start aling-items-center">
                <div class="row">
                  <div class="col-12 col-lg d-flex justify-content-start aling-items-center">
                    <h2><span class="circle_rounded"><img src="../assets/img/$.svg" style="margin-top: -3px;"></span> Con relacion a tus ingresos</h2>
                  </div>
                  <div class="col-12 col-lg ms-lg-4 d-flex justify-content-end aling-items-center my-4 d-lg-none px-0 img_ejemplo_contenedor">
                    <img src="../assets/img/diagnostico/diagnostico-ingresos.jpg" alt="" class="w-100">
                  </div>
                  <div class="col-3 px-0 d-flex justify-content-start justify-content-lg-end my-3 my-lg-0">
                    <button id="swiper-backV2" class="btn_slider"><img src="../assets/img/result/next_btn.svg" alt=""></button>
                    <button id="swiper-forwardV2" class="btn_slider"><img src="../assets/img/result/prev.svg" alt=""></button>
                  </div>
                </div>
                <div class="row h-100">
                  <swiper
                    :slidesPerView="1"
                    :spaceBetween="-50"
                    :navigation="{ nextEl: '#swiper-forwardV2', prevEl: '#swiper-backV2' , enabled: 'false'}"
                    :modules="modules"
                    :breakpoints="{
                      375: {
                        slidesPerView: 1,
                        spaceBetween: -10,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: -30,
                      },
                      992: {
                        slidesPerView: 1,
                        spaceBetween: -50,
                      }
                    }" 
                    class="mySwiper" >
                    <swiper-slide v-for="ingreso in ingresos">
                      <p class="excepcion">
                        <span>
                          <span v-html="ingreso.feedback"></span>
                          <br><br>
                          <span v-if="ingreso.num_enlaces >= 1" v-for="enlace in ingreso.enlaces">
                            <a :href="enlace.enlace">
                              <h6 v-html="enlace.nombre_producto"></h6>
                              <img src="../assets/img/Union.svg" alt=""/>
                            </a>
                          </span>
                        </span>
                      </p>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
              <div class="col-12 col-lg ms-lg-4 d-flex justify-content-end aling-items-center mt-3 mt-lg-0 d-none d-lg-block img_ejemplo_contenedor">
                <img src="../assets/img/diagnostico/diagnostico-ingresos.jpg" alt="">
              </div>
            </article>
            <!---->
            <article class="row row__width" v-if="gastos.length>0">
              <div class="col-12 col-lg me-lg-4 d-flex justify-content-start aling-items-center img_ejemplo_contenedor">
                <img src="../assets/img/diagnostico/diagnostico-gastos.jpg" alt="" class="w-100">
              </div>
              <div class="col-12 col-lg ms-lg-4 d-flex flex-column justify-content-end aling-items-center mt-3 mt-lg-0">
                <h2><span class="circle_rounded"><img src="../assets/img/gastos.svg" style="margin-top: -3px;"></span> Con relacion a tus gastos</h2>
                <p>
                    <span v-for="(gasto , id) in gastos">
                      <span v-html="gasto.feedback">
                    </span><br><br>
                    <template v-if="gasto.num_enlaces > 0">
                      <a v-for="(enlaces, id) in gasto.enlaces" :href="enlaces.enlace">
                        <h6>{{ enlaces.nombre_producto  }}</h6> <img src="../assets/img/Union.svg">
                      </a>
                    </template>
                  </span>
                </p>
              </div>
            </article>
            <!---->
            <article class="row row__width" v-if="ahorros.length>0">
              <div class="col-12 col-lg ms-lg-4 d-flex justify-content-end aling-items-center my-4 img_ejemplo_contenedor d-lg-none">
                <img src="../assets/img/diagnostico/diagnostico-ahorro.jpg" alt="" class="w-100">
              </div>
              <div class="col-12 col-lg me-lg-4 d-flex flex-column justify-content-start aling-items-center">
                <h2><span class="circle_rounded"><img src="../assets/img/ahorros.svg" style="margin-top: -3px;"></span> Con relacion a tus ahorros</h2>
                <p>
                    <span v-for="(ahorro , id) in ahorros">
                      <span v-html="ahorro.feedback">
                    </span><br><br>
                    <template v-if="ahorro.num_enlaces > 0">
                      <a v-for="(enlaces, id) in ahorro.enlaces" :href="enlaces.enlace">
                        <h6>{{ enlaces.nombre_producto  }}</h6> <img src="../assets/img/Union.svg">
                      </a>
                    </template>
                  </span>
                </p>
              </div>
              <div class="col-12 col-lg ms-lg-4 d-flex justify-content-end aling-items-center mt-3 mt-lg-0 img_ejemplo_contenedor d-none d-lg-flex">
                <img src="../assets/img/diagnostico/diagnostico-ahorro.jpg" alt="" class="w-100">
              </div>
            </article>
            <!---->
            <article class="row row__width" v-if="inversiones.length>0">
              <div class="col-12 col-lg me-lg-4 d-flex justify-content-start aling-items-center img_ejemplo_contenedor">
                <img src="../assets/img/diagnostico/diagnostico-inversiones.jpg" alt="" class="w-100">
              </div>
              <div class="col-12 col-lg ms-lg-4 d-flex flex-column justify-content-end aling-items-center mt-3 mt-lg-0">
                <h2><span class="circle_rounded"><img src="../assets/img/inversiones.svg" style="margin-top: -3px;"></span> Con relacion a tus inversiones</h2>
                <p>
                  <span v-for="(inversion, id) in inversiones"><span v-html="inversion.feedback"></span><br><br>
                    <template v-if="inversion.num_enlaces > 0">
                      <a v-for="(enlaces, id) in inversion.enlaces" :href="enlaces.enlace">
                        <h6>{{ enlaces.nombre_producto  }}</h6> <img src="../assets/img/Union.svg">
                      </a>
                    </template>
                  </span>
                </p>
              </div>
            </article>
            <!---->
            <article class="row row__width" v-if="mentalidades.length>0">
              <div class="col-12 col-lg ms-lg-4 d-flex justify-content-end aling-items-center my-4 img_ejemplo_contenedor d-lg-none">
                <img src="../assets/img/diagnostico/diagnostico-mentalidad.jpg" alt="" class="w-100">
              </div>
              <div class="col-12 col-lg me-lg-4 d-flex flex-column justify-content-start aling-items-center">
                <h2><span class="circle_rounded"><img src="../assets/img/mentalidad.svg" style="margin-top: -3px;"></span> Con relacion a tus mentalidad</h2>
                <p>
                <span v-for="(mentalidad, id) in mentalidades">
                  <span v-html="mentalidad.feedback"></span>
                  <br><br>
                    <template v-if="mentalidad.num_enlaces > 0">
                      <a v-for="(enlaces, id) in mentalidad.enlaces" :href="enlaces.enlace">
                        <h6>{{ enlaces.nombre_producto  }}</h6> <img src="../assets/img/Union.svg">
                      </a>
                    </template>
                  </span>
                </p>
              </div>
              <div class="col-12 col-lg ms-lg-4 d-flex justify-content-end aling-items-center mt-3 mt-lg-0 img_ejemplo_contenedor d-none d-lg-flex">
                <img src="../assets/img/diagnostico/diagnostico-mentalidad.jpg" alt="" class="w-100">
              </div>
            </article>
            <!---->
            <hr>
            <div class="row justify-content-center row__width">
              <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut" mode="out-in">
                <div class="d-flex flex-column justify-content-center align-items-center spinner" v-if="urlPdf === ''">
                  Cargando PDF...<br>
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <button v-else class="download_result d-flex justify-content-center align-items-center" @click="downloadResult">Descargar resultado <img src="../assets/img/result/arrowRoundedBottom.svg" class="ms-2"></button>
              </transition>

            </div>
          </div>
        </div>
        <footer>
          <div class="row justify-content-center box_verde">
            <div class="col-10 d-flex flex-column justify-content-center align-items-center">
              <h6>Quieres hablar con un asesor</h6>
              <a href="https://tulegadoderiqueza.com/contacto/">CONTACTAR <img src="../assets/img/result/next_black.svg" alt=""></a>
            </div>
          </div>
          <div class="row justify-content-center box_negra">
            <div class="col-10 d-flex justify-content-center align-items-center">
              <div class="row justify-content-center row__width">
                <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                  <h5>Copyright © 2022 LEGADO DE RIQUEZA HISPANOAMERICA LLC</h5>
                </div>
                <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center flex-cambio">
                  <a href="https://tulegadoderiqueza.com/aviso-legal-y-terminos-de-uso/">Aviso legal y términos de uso</a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

  </main>
</template>

<script>
import {jsPDF} from "jspdf"
import * as html2canvas from "html2canvas"
import { Swiper, SwiperSlide} from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
export default {
  name: "Result",
  data() {
    return {
      ingresos: [],
      inversiones:[],
      ahorros:[],
      gastos:[],
      mentalidades:[],
      respuestasUsadas:[],
      responses : [],
      tags: [],
      urlPdf: '',
      response: '',
      encodePDF: '',
      cargando: false
    }
  },
  methods: {
    getTexts(){
      if(localStorage.getItem('responses')) {
        this.responses = JSON.parse(localStorage.getItem('responses'))
        let responses = this.responses
        console.log(this.responses, 'responses')
        for(let i=0; i<responses.length; i++) {
          if (responses[i] !== null) {
            switch (responses[i].respuesta.clasificacion) {
              case "Candidato mentalidad":
                if(!this.tags.includes(3167)) {
                  this.tags.push(3167)
                }
                break;
              case "Candidato a multiplicar":
                if(!this.tags.includes(3165)) {
                  this.tags.push(3165)
                }
                break;
              case "Candidato producir":
                if(!this.tags.includes(3169)) {
                  this.tags.push(3169)
                }
                break;
              case "Candidato administrar":
                if(!this.tags.includes(3171)) {
                  this.tags.push(3171)
                }
                break;
              case "Candidato coaching":
                if(!this.tags.includes(3173)) {
                  this.tags.push(3173)
                }
                break;
            }
            if(parseInt(responses[i].respuesta.id_feedback) !== 1){
              //ingresos
              if (responses[i].id_pregunta === 2 || responses[i].id_pregunta === 3 || responses[i].id_pregunta === 4 || responses[i].id_pregunta === 5){
                this.ingresos.push(responses[i].respuesta)
              }
              //Gastos
              if (responses[i].id_pregunta === 6 || responses[i].id_pregunta === 7 || responses[i].id_pregunta === 8){
                  this.gastos.push(responses[i].respuesta)
              }
              //Ahorros
              if(responses[i].id_pregunta === 9 || responses[i].id_pregunta === 10){
                  this.ahorros.push(responses[i].respuesta)
              }
              //Inversiones
              if(responses[i].id_pregunta === 11) {
                this.inversiones.push(responses[i].respuesta)
              }
              //Mentalidad
              if(responses[i].id_pregunta === 12) {
                  this.mentalidades.push(responses[i].respuesta)
              }

            }

          }
        }
      }else{
         this.$router.replace('/step/0')
      }
    },
    getPDFurl(){
      if(localStorage.getItem('urlPdf')) {
        this.urlPdf = localStorage.getItem('urlPdf')
      }
    },
    setPdf(url){
      this.urlPdf = url
      localStorage.setItem('urlPdf', this.urlPdf)
      localStorage.setItem('sended','success')
      console.log('URL pdf: '+this.urlPdf)
    },
    getCapture(){
      //download the file using js pure

      let pdfRef = this.$refs.pdf;

      html2canvas(pdfRef).then(canvas => {
        let pdfImage = canvas.toDataURL();

        let pdf = new jsPDF(
          "p",
          "mm",
          "a4"
        );
        pdf.addImage(
          pdfImage,
          "PNG",
          -75,
          0,
          500,
          1164
        );
        pdf.save("descarga.pdf");
      })
    },
    downloadResult() {
      //Abrir en una nueva ventana la url del PDF
      window.open(this.urlPdf, '_blank')
    },
    sendInfo() {
      let name = localStorage.getItem('name')
      let email = localStorage.getItem('email')
      let tags = this.tags

      let arraySecciones = {
        ingresos:{
          respuestas: [
          ]
        },
        gastos: {
          respuestas: [
          ]
        },
        ahorros:{
          respuestas: [
          ]
        },
        inversiones:{
          respuestas: [

          ]
        },
        mentalidad:{
          respuestas: [
          ]
        }
      }
      //push respuestas{ textoRespuesta string, links array  }
      if(this.ingresos.length > 0) {
        for(let i=0; i<this.ingresos.length; i++) {
          arraySecciones.ingresos.respuestas.push({
            textoRespuesta: this.ingresos[i].feedback,
            links: this.ingresos[i].enlaces
          })
        }
      }
      if(this.gastos.length > 0) {
        for(let i=0; i<this.gastos.length; i++) {
          arraySecciones.gastos.respuestas.push({
            textoRespuesta: this.gastos[i].feedback,
            links: this.gastos[i].enlaces
          })
        }
      }
      if(this.ahorros.length > 0) {
        for(let i=0; i<this.ahorros.length; i++) {
          arraySecciones.ahorros.respuestas.push({
            textoRespuesta: this.ahorros[i].feedback,
            links: this.ahorros[i].enlaces
          })
        }
      }
      if(this.inversiones.length > 0) {
        for(let i=0; i<this.inversiones.length; i++) {
          arraySecciones.inversiones.respuestas.push({
            textoRespuesta: this.inversiones[i].feedback,
            links: this.inversiones[i].enlaces
          })
        }
      }
      if(this.mentalidades.length > 0) {
        for(let i=0; i<this.mentalidades.length; i++) {
          arraySecciones.mentalidad.respuestas.push({
            textoRespuesta: this.mentalidades[i].feedback,
            links: this.mentalidades[i].enlaces
          })
        }
      }

      let data = {
        nombre : name,
        email: email,
        tags: tags,
        arraySecciones: arraySecciones
      }
      //fetch post data to url
      fetch('https://tulegadoderiqueza.com/admin_diagnostico/', {
        method: 'PUT',
        body: JSON.stringify(data)}).then(
        res => res.json(),
      ).then((data) => this.setPdf(data.pdf.url)).
      catch(error => console.error('Error:', error))
    },
    getURlCreated(){

    }
  },
  created() {
    this.getTexts()
    this.getPDFurl()
    /*console.log(this.ingresos)*/
    //if isset values email, name, stepPage and responses, delete all localstorage
    if(!localStorage.getItem('sended')) {
      console.log('sended')
      this.sendInfo()
    }
    //console.log(this.urlPdf)
    localStorage.setItem("stepPage", 0)
    console.log(this.gastos, 'gastos', )
  },
  computed: {
    dataForm(){
      let name = localStorage.getItem('name')
      let email = localStorage.getItem('email')
      let tags = this.tags
      return {
        nombre: name,
        email: email,
        tags: tags
      }
    },
  },
  components:{
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>

<style scoped lang="scss">
.row__width{
  width: 100%;
}
@font-face{
  font-family: 'Aeonik';
  src: url('@/assets/fonts/AeonikRegular.otf');
}
@font-face{
  font-family: 'Aeonik Bold';
  src: url('@/assets/fonts/AeonikBold.otf');
}
main{
  background: #062628;
  .header_results{
    background: #031617;
    backdrop-filter: blur(7.5px);
    min-height: 13vh;
    a{
      font-family: 'Visby';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
  .barrita_blanca{
    background-color: #E0FFD1;
    max-width: 1px;
    height: 52px;
    position: absolute;
    left: 50%;
    bottom: 0;
    padding: 0;
    @media (min-width: 1024px) {
      height: 72px;
    }
  }
  .barrita_verde{
    background-color: #0C201A;
    max-width: 1px;
    height: 52px;
    position: absolute;
    left: 50%;
    top: 0;
    padding: 0;
    @media (min-width: 1024px) {
      height: 72px;
    }
  }
  .contenido{
    h1{
      font-family: 'Visby';
      font-style: normal;
      font-weight: 600;
      line-height: 99.9%;
      color: #FFFFFF;
      font-size: 28px;
      padding: 15px 0px;
      @media (min-width: 1024px) {
          font-size: 50px;
          padding: 50px 0px;
      }
    }
    h4{
      font-family: 'Visby';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 33px;
      color: #A9BBB6;
      padding: 20px 0px;
      @media (min-width: 1024px) {
        font-size: 24px;
        padding: 30px 0px;
      }
    }
  }
  .section{
    background: #F9FAF4;
    border-radius: 18px;
    article{
      margin-top: 5em;
      margin-bottom: 5em;
      h2{
        margin-bottom: 1em;
        font-family: 'Aeonik Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 38px;
        color: #17383A;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @media (min-width: 1024px) {
          font-size: 32px;
          justify-content: flex-start;
        }
      }
      .circle_rounded{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #E0FFD1;
        display: flex;
        margin-right: 10px;
        margin-bottom: -10px;
        text-align: center;
        justify-content: center;
        align-items: center;
        img{
          height: auto;
          min-height: 50%;
        }
        @media (min-width: 1024px) {
          min-height: 50px;
          min-width: 50px;
        }
      }
      p{
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #000000;
        background: white;
        padding: 45px;
        border-radius: 18px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media(max-width: 992px){
          min-width: 92%;
          p{
            padding: 40px;
          }
        }
        a{
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #062628;
          text-decoration: none;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 15px;
        }
      }
      span{
        width: 100%;
        margin-bottom: 10px;
      }
      .excepcion{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img{
        height: 100%;
        max-width: 100%;
      }
      .img_ejemplo_contenedor{
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 30px;
        }
      }
      a{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h6{
          font-family: 'Aeonik';
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #062628;
          border-bottom: 6px solid #E0FFD1;
        }
        img{
          margin-left: 10px;
        }
      }
      @media (max-width: 1024px) {
        margin-top: 0em;
        margin-bottom: 2em;
      }
    }
    .download_result{
      background: #062628;
      border-style:none;
      border: 1px solid #C2A984;
      border-radius: 87px;
      color:white;
      font-family: 'Aeonik';
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      text-decoration: none;
      letter-spacing: 0.065em;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 14px;
      width: 100%;
      height: 90px;
      @media (min-width: 992px) {
        width: 45%;
        height: 120px;
        font-size: 18px;
      }
    }
    hr{
      opacity: 1;
      width: 100%;
    }
  }
  footer{
    .box_verde{
      background-color: #062628;
      min-height: 47vh;
      h6{
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.04em;
        color: #FFFFFF;
        padding-bottom: 10%;
        text-align: center;
      }
      a{
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.065em;
        color: #0B0B0B;
        background: #DCC078;
        border: 1px solid #C2A984;
        border-radius: 87px;
        text-decoration: none;
        img{
          margin-left: 15px;
        }
        min-width: 75%;
        font-size: 14px;
        text-align: center;
        padding: 7px 0px;
      }
      @media (min-width: 1024px) {
        min-height: 80vh;
        h6{
          font-size: 50px;
          padding-bottom: 5%;
          line-height: 60px;
        }
        a{
          min-width: 18%;
          font-size: 18px;
          padding: 20px;
        }
      }
    }
    .box_negra{
      background: #000000;
      min-height: 7vh;
      h5, a{
        font-family: 'Visby';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 136.4%;
        color: #B0B0B0;
        text-decoration: none;
        text-align: center;
        padding-top: 7px;
      }
      a{
        margin: 0px 30px;
        &:last-child{
          padding-bottom: 7px;
        }
      }
      @media (min-width: 992px) {
        h5, a{
          font-size: 12px;
          padding: 0;
        }
        a:last-child{
          padding-bottom: 0px;
        }
      }
    }
  }
  .btn_slider{
    border: 1px solid #17383A;
    border-radius: 50%;
    background-color: transparent;
    outline: none;
    max-height: 40px;
    margin: 0px 5px;
    max-width: 40px;
    @media (min-width: 768px) {
      height: 60px;
      width: 50px;
    }
    @media (min-width: 1024px) {
      height: 70px;
      width: 60px;
    }
  }
  .swiper-slide{
    color: #fff;
    min-height: 450px;
    p{
      max-width: 85%;
      padding: 40px;
    }
  }
  @media (max-width: 1024px) {
    .flex-cambio{
      flex-direction: column;
    }
  }
  
}
.spinner{

}
</style>